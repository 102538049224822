import { Styles } from "@chakra-ui/theme-tools"

export const styles: Styles = {
  global: {
    // styles for the `body`
    body: {
      // fontColor: "red",
    },
    // styles for the `a`
    // a: {
    //   color: "teal.500",
    //   _hover: {
    //     textDecoration: "underline",
    //   },
    // },
  },
}

// export default theme
